<template>
  <v-dialog v-model="dialogEditShow" max-width="800px">
    <v-card class="pa-4 pa-sm-8">
      <h4 class="mb-5">Выберите блок вопросов, который нужно скопировать</h4>
      <v-btn class="button-close ma-4" icon @click="dialogEditShow=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-treeview style="height: 400px;">

      </v-treeview>

      <div class="row mt-4 justify-end">
          <v-btn class="mr-3 _w-100" _color="primary" @click="dialogEditShow=false"> Закрыть </v-btn>
          <!-- <v-btn class="_w-100" color="primary" @click="okClicked"> Сохранить </v-btn> -->
       </div>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/

export default {
  components: {
  },
  model: {
     prop: 'value',
     event: 'input'
  },
  props: {
    value: Boolean,
    question: Object,
  },
  data: () => ({
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
      }
    }
  },
  computed: {
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', false);
      }
    },
  },
  methods: {
    okClicked() {
      this.$emit('ok');
      this.dialogEditShow = false
    },
  }
};
</script>


