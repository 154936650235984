<template>
  <div>
      <image-selector v-model="showImagesDialog" @insertImage="insertImage" />
      <div v-if="editor" class="editor-toolbar">
        <div class="mt-2">{{ title }}</div>
        <div class="ml-auto">
          <!-- <v-btn icon @click="changeMode()" :class="{ 'is-active': srcMode }">
              src
          </v-btn>
          <v-btn text icon @click="editor.chain().focus().undo().run()" :disabled="srcMode">
              <v-icon>mdi-undo</v-icon>
          </v-btn>
          <v-btn text icon @click="editor.chain().focus().redo().run()" :disabled="srcMode">
              <v-icon>mdi-redo</v-icon>
          </v-btn>
          <v-btn icon
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              :disabled="srcMode"
          >
              H1
          </v-btn>
          <v-btn icon
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              :disabled="srcMode"
          >
              <b>H2</b>
          </v-btn>
          <v-btn icon
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              :disabled="srcMode"
          >
              <b>H3</b>
          </v-btn> -->
          <v-btn icon
              @click="editor.chain().focus().toggleBold().run()"
              :class="{ 'is-active':editor.isActive('bold')}"
              :disabled="srcMode"
          >
              <b>B</b>
          </v-btn>
          <v-btn icon
              @click="editor.chain().focus().toggleItalic().run()"
              :class="{ 'is-active':editor.isActive('italic')}"
              :disabled="srcMode"
          >
              <b>I</b>
          </v-btn>
          <!-- <v-btn icon
              @click="editor.chain().focus().toggleCodeBlock().run()"
              :class="{ 'is-active': editor.isActive('codeBlock') }"
              :disabled="srcMode"
          >
              code
          </v-btn>
          <v-btn icon @click="editor.chain().focus().setHorizontalRule().run()" :disabled="srcMode">
              <b>HR</b>
          </v-btn>
          <v-btn icon @click="setLink()" :disabled="srcMode" :class="{ 'is-active': editor.isActive('link') }">
              <v-icon>mdi-link</v-icon>
          </v-btn>
          <v-btn icon @click="showImagesDialog = true" :disabled="srcMode">
              <v-icon>mdi-image</v-icon>
          </v-btn> -->
          <!-- <v-btn icon  @click="editor.chain().focus().setDetails().run()" :disabled="!editor.can().setDetails()">
              <v-icon>mdi-playlist-plus</v-icon>
          </v-btn>
          <v-btn icon @click="editor.chain().focus().unsetDetails().run()" :disabled="!editor.can().unsetDetails()">
              <v-icon>mdi-playlist-remove</v-icon>
          </v-btn> -->
        </div>
      </div>
      <editor-content v-if="!srcMode" class="editor-box" :editor="editor"/>
      <v-textarea v-else dense outlined hide-details no-resize height="180" label="Html" v-model="srcContent" @input="changed()" />
  </div>
</template>

<script>
/* eslint-disable */
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import ImageSelector from './ImageSelector';
import Dropcursor from '@tiptap/extension-dropcursor'
import CustomImage from './CustomImageExtention'
import Placeholder from '@tiptap/extension-placeholder'

// import Details from '@/components/tiptap-pro/extension-details'
// import DetailsSummary from '@/components/tiptap-pro/extension-details-summary'
// import DetailsContent from '@/components/tiptap-pro/extension-details-content'

export default {
components: {
    EditorContent, ImageSelector
  },
  model: {
    prop: 'content',
    event: 'input'
  },
  props: {
    content: String,
    update: Boolean,
    title: String,
  },
  data() {
    return {
      showImagesDialog: false,
      commandAddImage: null,
      srcMode: false,
      srcContent: '',
      editor: new Editor({
        content: this.content,
        extensions: [
            StarterKit,
            // Details.configure({
            //   persist: false,
            //   HTMLAttributes: {
            //     class: 'details',
            //   },
            // }),
            // DetailsSummary,
            // DetailsContent,
            Placeholder.configure({
              includeChildren: true,
              placeholder: ({ node }) => {
                // if (node.type.name === 'detailsSummary') {
                //   return 'Summary'
                // }
                return null
              },
            }),
            Dropcursor,
            Link.configure({
                HTMLAttributes: {
                        rel: 'nofollow'
                    }
                }),
            CustomImage.configure({
                    HTMLAttributes: {
                        // class: 'custom-image'
                    }
                }),
        ],
        onUpdate: () => {
            const html = this.editor.getHTML()
            this.$emit('input', html)
        },
      })
    }
  },
  watch: {
      update() {
          this.srcMode = false
          this.editor.commands.setContent(this.content, false)
      },
  },
  methods:{
      insertImage({ src, width }) {
          this.showImagesDialog = false
          if (src)
            this.editor.chain().focus().setImage({ src, width }).run()
      },
      setLink() {
        const url = window.prompt('URL')

        this.editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_self' }).run()
      },
      changeMode() {
        this.srcMode = !this.srcMode
        if (this.srcMode) {
            this.srcContent = this.editor.getHTML()
        } else {
            this.editor.commands.setContent(this.srcContent, false)
        }

      },
      changed() {
          this.$emit('input', this.srcContent)
      }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
};
</script>

<style lang="scss">

$editor_height: 120px;

.ProseMirror {
    height: $editor_height;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 6px;
    outline: none;
    padding: 5px;
    transition: all 0.3s ease;
    overflow: auto;

  > * + * {
    margin-top: 0.25em;
  }

  p {
    margin-bottom: 0px;
  }

  ul, ol {
    padding: 0 1rem;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  a {
      color: #e53031;
  }

  .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  // .details {
  //   display: flex;
  //   margin: 1rem 0;
  //   border: 1px solid #eee;
  //   border-radius: 0.5rem;
  //   padding: 0.5rem;

  //   > button {
  //     display: flex;
  //     cursor: pointer;
  //     background: transparent;
  //     border: none;
  //     padding: 0;

  //     &::before {
  //       content: '\25B6';
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       width: 1.5em;
  //       height: 1.5em;
  //       transition: 0.3s all ease;
  //       font-size: 1.4rem;
  //       margin-top: -3px;
  //     }
  //   }

  //   &.is-open > button::before {
  //     transform: rotate(90deg);
  //   }

  //   > div {
  //     flex: 1 1 auto;
  //   }

  //   :last-child {
  //     margin-bottom: 0;
  //   }
  // }
  // summary {
  //   display: block;
  //   margin: 3px 0 8px 0;
  //   font-size: 1rem;
  // }

  // div[data-type=detailsContent] {
  //   color: #000;
  // }

}

.ProseMirror-focused {
    border: 1px solid rgba(0, 87, 116, 0.592)
}

.editor-toolbar {
    padding-top: 8px !important;
    padding-bottom: 4px !important;
    // text-align: right;
    display: flex;
    button {
        margin-right: 2px;
    }
}

.is-active {
    background-color: #adb5bd;
    // background-color: map-get($blue-grey, 'lighten-5'),
}

.src-editor {
    height: $editor_height;
}

</style>
