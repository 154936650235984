<template>
  <div class="test-editor">
    <v-tabs-items v-model="tab" class="pa-4">
      <v-tab-item value="TestList">
        <div class="row justify-space-between align-center mb-6">
            <h2 class="col">Сборки тестирования</h2>
            <!-- Доделать потом  -->
            <v-btn class="mr-3" dark color="primary" @click="viewTests()" :disabled="!$store.getters['auth/isAllowed']('view_instrument')">
              Инструменты
            </v-btn>
        </div>
        <div class="items-list-wrapper">
          <transition-group class="items-list w-100" name="list-complete" tag="div">
            <div v-if="!instrumentSets || !instrumentSets.length" class="mb-4" :key="-1">Добавьте сборки тестирования</div>
            <!-- <div v-else class="mb-4" :key="-3">Вопросы:</div> -->
            <div class="row row-complete-item item-question" v-for="(instrumentSet, ind) in instrumentSets" :key="instrumentSet.id">
              <div class="col col-test-name d-flex align-center">
                <div class="mr-2" :class="{'text-gray': instrumentSet.status!=='published'}">{{ ind+1 }}.</div>
                <div :class="{'text-gray': instrumentSet.status!=='published'}"> {{instrumentSet.name}} </div>
              </div>
              <div class="col col-buttons-2 text-right">
                <v-btn icon color="primary" @click="editItem(instrumentSet)" :disabled="!$store.getters['auth/isAllowed']('change_instrumentset')">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <!-- <v-btn icon color="primary"><v-icon>mdi-filter-outline</v-icon></v-btn> -->
                <v-btn icon color="error" @click="askDelete(instrumentSet)" :loading="loading.del===instrumentSet.id" :disabled="!$store.getters['auth/isAllowed']('delete_instrumentset')">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="row row-complete-item" :key="-2" >
                <v-btn class="add-button" fab dark small color="primary" @click="addItem()" :disabled="!$store.getters['auth/isAllowed']('add_instrumentset')">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>
          </transition-group>
        </div>

      </v-tab-item>

      <v-tab-item value="TestCard" class="">
        <TestCard :instrumentSet="curInstrumentSet" @back="tab='TestList'"/>
      </v-tab-item>
    </v-tabs-items>

    <AskDialog v-model="showAskDeleteDialog" @ok="delInstrumentSet(curInstrumentSet)" />
    <CreateSetDialog v-model="showCreateDialog" @ok="delInstrumentSet(curInstrumentSet)" />
  </div>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import AskDialog from "@/components/ui/AskDialog"
import TestCard from '@/components/testEditor/TestCard.vue';
import CreateSetDialog from '@/components/testEditor/CreateSetDialog.vue';

export default {
  components: {
    AskDialog, TestCard, CreateSetDialog
  },
  data() {
    return {
      tab: 'TestList',
      showAskDeleteDialog: false,
      curInstrumentSet: null,
      showCreateDialog: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loading: 'testEditor/loading',
      instrumentSets: 'testEditor/instrumentSets',
    }),
  },
  methods: {
    ...mapActions({
      // addInstrumentSet: 'testEditor/addInstrumentSet',
      delInstrumentSet: 'testEditor/delInstrumentSet',
    }),
    askDelete(instrumentSet) {
      this.showAskDeleteDialog = true
      this.curInstrumentSet = instrumentSet
    },
    editItem(instrumentSet) {
      this.curInstrumentSet = instrumentSet
      this.tab = 'TestCard'
    },
    addItem() {
      this.showCreateDialog = true
      //  this.addInstrumentSet({customer_id : this.user.id})
    },
    viewTests() {
      this.curInstrumentSet = null
      this.tab = 'TestCard'
    }
  }

}
</script>

<style>
</style>
