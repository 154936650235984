<template>
  <div class="card">
    <v-tabs-items v-model="tab" class="">
    <v-tab-item value="TestCard">
      <!-- -{{instruments}}- -->
      <div class="d-flex align-center mb-6">
          <v-btn icon @click="$emit('back')" class="mr-1"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h2 class="">{{instrumentSet ? 'Редактирование сборки тестирования' : 'Инструменты'}}</h2>
      </div>
      <div class="row">
        <div v-if="instrumentSet" class="col-sm-6">
          <v-text-field v-model="instrumentSet.name" label="Заголовок сборки тестирования:" dense hide-details class="mb-7" @input="debouncedChanged"/>
          <v-select item-value="id" v-model="instrumentSet.status" :items="statuses" label="Статус" dense hide-details class="mb-6" @change="onChange" />

          <!-- <v-select v-model="instrumentSet.customer_id" :items="filterManagers" dense hide-details @change="onChange" label="Клиент" item-value="id"
            :item-text="(item) => item.company ? item.company : 'имя не задано'" class="mb-6"
          /> -->

          <v-text-field v-model="instrumentSet.code" label="Код сборки:" dense class="mb-2" readonly color="grey" />

          <div class="items-list-wrapper">
            <transition-group class="items-list w-100" name="list-complete" tag="div">
              <div v-if="!instrumentSet.instruments || !instrumentSet.instruments.length" class="mb-4" :key="-1">Добавьте опросники</div>
              <div class="item-question-hint" :key="-11">
                Для добавления инструмента в сборку нажимайте <v-icon size="10px" color="primary">mdi-arrow-left</v-icon> в списке доступных инструментов
              </div>
              <div v-if="instrumentSet.instruments && instrumentSet.instruments.length" class="mb-4" :key="-3">
                Инструменты, включенные в сборку:
              </div>
              <div class="row row-complete-item item-question" v-for="(instrument, ind) in instrumentSet.instruments" :key="instrument.instrument_id">
                <div class="col-9 d-flex align-center">
                  <div class="mr-2">{{ ind+1 }}.</div>
                  <div> {{getInstrumentName(instrument.instrument_id)}} </div>
                </div>
                <div class="col-3 pl-3 d-flex justify-end">
                  <!-- <div class="d-flex flex-column mr-2">
                    <v-btn icon color="primary"  x-small>
                      <v-icon style="width:12px; height: 12px;" x-small size="10px">mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn icon color="primary" :loading="loading.loadItem===instrument.id" x-small>
                      <v-icon style="width:12px; height: 12px;" x-small size="10px">mdi-arrow-down</v-icon>
                    </v-btn>
                  </div> -->

                  <v-btn icon color="error" @click="deleteFromSet(ind)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <div :class="{ 'col-sm-6 _align-lists':instrumentSet, 'col-12': !instrumentSet}" >
          <v-textarea v-if="instrumentSet" label="Описание" v-model="instrumentSet.description" no-resize height="120" @input="debouncedChanged"/>
          <div class="items-list-wrapper">
            <transition-group class="items-list w-100" name="list-complete" tag="div">
              <div v-if="!instruments || !instruments.length" class="mb-4" :key="-1">Добавьте опросники</div>
              <div v-else class="pt-2 pb-2 mb-4" :key="-3">{{instrumentSet ? 'Доступные инструменты:' : 'Список инструментов'}}</div>
              <div class="row row-complete-item item-question" v-for="(instrument, ind) in instruments" :key="instrument.id">
                <div class="col col-test-name d-flex align-center">
                  <v-btn v-if="instrumentSet" icon @click="addToSet(instrument)" class="mr-1" :disabled="!canAdd(instrument)">
                    <v-icon class="d-none d-sm-block" color="primary">mdi-arrow-left</v-icon>
                    <v-icon class="d-block d-sm-none" color="primary">mdi-plus</v-icon>
                  </v-btn>
                  <div class="mr-2">{{ ind+1 }}.</div>
                  <div> {{instrument.name}} </div>
                </div>
                <div class="col col-buttons-2 text-right">
                  <v-btn icon color="primary" @click="editItem(instrument)" :loading="loading.loadItem===instrument.id"
                    :disabled="!$store.getters['auth/isAllowed']('change_instrument')"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="primary"><v-icon>mdi-filter-outline</v-icon></v-btn> -->
                  <v-btn icon color="error" @click="askDelete(instrument)" :loading="loading.del===instrument.id"
                    :disabled="!$store.getters['auth/isAllowed']('delete_instrument')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="row row-complete-item" :key="-2">
                <v-btn class="add-button" rounded dark color="primary" @click="showCreateInstrumentDialog=true" :loading="loading.add"
                  :disabled="!$store.getters['auth/isAllowed']('add_instrument')"
                >
                    <v-icon>mdi-plus</v-icon> Добавить новый опросник
                </v-btn>
                <!-- <v-btn class="add-button ml-4" rounded dark color="grey" @click="showQuestinnaireSelectForCopy=true" >
                  <v-icon small class="mr-2">mdi-content-copy</v-icon> Добавить копию опросника
                </v-btn> -->
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      </v-tab-item>
      <v-tab-item value="BlockList" class="">
        <QuestionnaireCard :instrument="curInstrument" @back="tab='TestCard'"/>
      </v-tab-item>
    </v-tabs-items>
    <QuestionnaireSelectForCopy v-model="showQuestinnaireSelectForCopy" />
    <AskDialog v-model="showAskDeleteDialog" @ok="delInstrument(curInstrument)" />
    <CreateInstrumentDialog v-model="showCreateInstrumentDialog" @ok="onCreateInstrument" />
  </div>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import AskDialog from "@/components/ui/AskDialog"
import QuestionnaireCard from '@/components/testEditor/QuestionnaireCard.vue';
import QuestionnaireSelectForCopy from '@/components/testEditor/QuestionnaireSelectForCopy.vue';
import CreateInstrumentDialog from '@/components/testEditor/CreateInstrumentDialog.vue';


export default {
  components: {
    AskDialog, QuestionnaireCard, QuestionnaireSelectForCopy, CreateInstrumentDialog
  },
  props: {
    instrumentSet: Object,
  },
  data() {
    return {
      tab: 'TestCard',
      showAskDeleteDialog: false,
      showCreateInstrumentDialog: false,
      showQuestinnaireSelectForCopy: false,
      curInstrument: 0,
      statuses: [{ id: 'draft', text: "Черновик" }, { id: 'published', text: "Опубликован"}, { id: 'archived', text: "Архив"}],
      code: '',
    };
  },
  computed: {
    ...mapGetters({
      loading: 'testEditor/loading',
      instruments: 'testEditor/instruments',
      clients: 'users/clients/clients',
    }),
    filterManagers() {
      const arr = this.clients.slice();
      arr.sort(function (item1, item2) {
        const a = item1.company ?? 'имя не задано';
        const b = item2.company ?? 'имя не задано';
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      return arr;
    },
  },
  methods: {
    ...mapActions({
      saveInstrumentSet: 'testEditor/saveInstrumentSet',
      addInstrument: 'testEditor/addInstrument',
      delInstrument: 'testEditor/delInstrument',
      loadInstrument: 'testEditor/loadInstrument',
    }),
    async onCreateInstrument(description) {
      const newInstrument = await this.addInstrument({description})
      this.editItem(newInstrument)
    },
    getInstrumentName(id) {
      const item = this.instruments.find(el => el.id===id)
      return item ? item.name : 'Не найден'
    },
    askDelete(instrument) {
      this.showAskDeleteDialog = true
      this.curInstrument = instrument
    },
    async editItem(instrument) {
      this.curInstrument = await this.loadInstrument(instrument)
      this.tab = 'BlockList'
    },
    addToSet(instrument) {
      this.instrumentSet.instruments.push({
        instrument_id: instrument.id, 
        order: 0,
        // show_condition": 0        
      })
      this.onChange()
    },
    canAdd(instrument) {
      return !this.instrumentSet.instruments.find(el => el.instrument_id === instrument.id)
    },
    deleteFromSet(ind) {
      this.instrumentSet.instruments.splice(ind, 1)
      this.onChange()
    },
    onChange() {
      this.saveInstrumentSet(this.instrumentSet)
    },
    debouncedChanged: debounce(function (val) {
      this.onChange();
    }, 500),
  }

}
</script>

<style scoped>
.align-lists {
  padding-top: 170px 
}

@media screen and (max-width: 600px) {
  .align-lists {
    padding-top: 17px 
  }
}  

</style>
