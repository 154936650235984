<template>
    <v-dialog v-model="dialogEditShow" max-width="1100px">
        <v-card class="pa-8 test-editor">
            <!-- {{ instrumentSet }} -->
            <div class="d-flex align-center mb-6">
                <h2 class="">Создание опросника</h2>
                <v-btn class="button-close ma-4" icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="row">
                <v-textarea label="Введите описание опросника" v-model="desc" outlined />
            </div>
            <div class="row mt-4 justify-end">
                <v-btn class="mr-3 _w-100" @click="close()"> Закрыть </v-btn>
                <v-btn class="_w-100" color="primary" @click="okClicked"> Создать опросник </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
/* eslint-disable */
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    components: {
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: Boolean,
    },
    data() {
        return {
            desc: '',
        };
    },
    watch: {
        async dialogEditShow(newVal) {
            if (newVal) {
                this.desc = ''
            }
        }
    },
    computed: {
        ...mapGetters({
            loading: 'testEditor/loading',
        }),
        dialogEditShow: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', false);
            }
        },
    },
    methods: {
        ...mapActions({
        }),
        ...mapMutations({
        }),
        async okClicked() {
            this.$emit('ok', this.desc)
            this.dialogEditShow = false
        },
        close() {
            this.dialogEditShow = false
        },
    }

}
</script>

<style></style>