<template>
  <div class="card">
    <!-- <v-text-field v-model="question.title" label="Заголовок:" outlined dense hide-details/> -->
    <html-content-editor v-model="question.text" :update="forceUpdate" @input="changed()" title="Текст:" class="mb-6"/>
  </div>
</template>

<script>
/* eslint-disable */
import HtmlContentEditor from '@/components/testEditor/htmlEditor/HtmlContentEditor.vue';

export default {
  components: {
    HtmlContentEditor
  },
  props: {
    question: Object,
  },
  data() {
    return {
      forceUpdate: false,
    };
  },
  watch: {
    'question.id'() {
      this.forceUpdate = !this.forceUpdate
    }
  },
  methods: {
    changed() {
    }
  }

}
</script>

<style>
</style>
