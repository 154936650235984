<template>
  <div class="">
    <v-tabs-items v-model="mode" class="">
      <v-tab-item value="editBlockList">
        <!-- {{instrument.blocks}} -->
        <div class="d-flex align-center mb-6">
          <v-btn icon @click="$emit('back')" class="mr-1"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h2 class="">Редактирование опросника</h2>
        </div>
        <v-text-field v-model="instrument.name" label="Заголовок опросника:" dense hide-details class="mb-4" @input="debouncedChanged"/>
        <v-textarea label="Описание" v-model="instrument.description" no-resize hide-details rows="2" @input="debouncedChanged"/>
        <v-switch v-model="instrument.is_random_ordered" label="Случайный порядок" hide-details inset :ripple="false" class="ml-1" @change="onChange" />
        <v-switch v-model="instrument.is_included_in_report" label="Включен в отчет" hide-details inset :ripple="false" class="ml-1 mb-3" @change="onChange" />
        <h2 class="mb-6">Блоки вопросов</h2>
        <div class="items-list-wrapper">
          <transition-group class="items-list w-100" name="list-complete" tag="div">
            <div v-if="!instrument.blocks || !instrument.blocks.length" class="mb-4" :key="-1">Добавьте блоки вопросов</div>
            <!-- <div v-else class="mb-4" :key="-3">Вопросы:</div> -->
            <div class="row row-complete-item item-question" v-for="(block, ind) in instrument.blocks" :key="block.id">
              <div class="col col-test-name d-flex align-center">
                <div class="mr-2">{{ ind+1 }}.</div>
                <div> {{block.title}} </div>
              </div>
              <div class="col col-buttons-2 text-right">
                <v-btn icon color="primary" @click="editItem(block)" :disabled="!$store.getters['auth/isAllowed']('change_block')">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <!-- <v-btn icon color="primary"><v-icon>mdi-filter-outline</v-icon></v-btn> -->
                <v-btn icon color="error" @click="askDelete(block)" :loading="loading.del===block.id" :disabled="!$store.getters['auth/isAllowed']('delete_block')">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="row row-complete-item" :key="-2">
                <v-btn class="add-button mx-2 mb-2" rounded dark color="primary" @click="addBlock({instrument})"
                  :loading="loading.add" :disabled="!$store.getters['auth/isAllowed']('add_block')"
                >
                  <v-icon>mdi-plus</v-icon> Добавить новый блок
                </v-btn>
                <!-- <v-btn class="add-button mx-2 mb-2" rounded dark color="grey" @click="showBlockSelectForCopy=true" >
                  <v-icon small class="mr-2">mdi-content-copy</v-icon> Добавить копию блока
                </v-btn> -->
            </div>
          </transition-group>
        </div>
        <!-- <div class="items-list-wrapper mt-7">
            <div class="mb-4" :key="-1">Условия отображения опросника:</div>
        </div> -->

      </v-tab-item>

      <v-tab-item value="editBlock" class="">
        <div class="d-flex align-center mb-6">
          <v-btn icon @click="mode='editBlockList'" class="mr-1"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h2 class="">Редактирование блока вопросов</h2>
        </div>
        <BlockCard :block="curBlock"/>
      </v-tab-item>
    </v-tabs-items>

    <AskDialog v-model="showAskDeleteDialog" @ok="delBlock(curBlock)" />
    <BlockSelectForCopy v-model="showBlockSelectForCopy" />
  </div>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import AskDialog from "@/components/ui/AskDialog"
import BlockCard from '@/components/testEditor/BlockCard.vue';
import BlockSelectForCopy from '@/components/testEditor/BlockSelectForCopy.vue';

export default {
  components: {
    AskDialog, BlockCard, BlockSelectForCopy
  },
  props: {
    instrument: Object,
  },
  data() {
    return {
      mode: 'editBlockList',
      showAskDeleteDialog: false,
      showBlockSelectForCopy: false,
      curBlock: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'testEditor/loading',
      blocks: 'testEditor/blocks',
    }),
  },
  methods: {
    ...mapActions({
      saveInstrument: 'testEditor/saveInstrument',
      addBlock: 'testEditor/addBlock',
      delBlock: 'testEditor/delBlock',
      loadBlock: 'testEditor/loadBlock',
    }),
    askDelete(block) {
      this.showAskDeleteDialog = true
      this.curBlock = block
    },
    async editItem(block) {
      this.curBlock = await this.loadBlock(block)
      this.mode = 'editBlock'
    },
    onChange() {
      this.saveInstrument(this.instrument)
    },
    debouncedChanged: debounce(function (val) {
      this.onChange();
    }, 500),

  }

}
</script>

<style>
</style>
