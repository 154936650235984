<template>
  <v-dialog v-model="dialogEditShow" max-width="1024px" persistent>
    <v-card class="pa-4 pa-sm-8 test-editor">
      <h4 class="mb-5">Интервалы шкал для блока "{{ block.title }}"</h4>
      <v-btn class="button-close ma-4" icon @click="dialogEditShow=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <!-- {{ intervals }} -->
      <div class="items-list-wrapper">
        <transition-group class="items-list w-100" name="list-complete" tag="div">
          <div v-if="!intervals || !intervals.length" class="mb-4" :key="-1">Добавьте интервалы</div>
          <div class="row row-complete-item item-question" v-for="interval in intervals" :key="interval.idTmp">
            <div class="col-12 col-sm-5 d-flex align-center">
              <v-range-slider :tick-labels="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :color="errorIntervals.includes(interval.idTmp) ? 'red' : ''"
                :value="[interval.value_from, interval.value_to]" @change="setRange(interval, $event)" :min="0"
                :max="10" ticks="always" tick-size="3" track-color="#ddd" hide-details class="interval-ticks">
                <template v-slot:thumb-label="props">
                  {{ props.value }}
                </template>
              </v-range-slider>
            </div>
            <div class="col-12 col-sm-7 d-flex align-center">
              <!-- <v-textarea v-model="interval.description" _outlined label="Описание"  rows="2" _no-resize hide-details /> -->
              <html-content-editor v-model="interval.description" :update="forceUpdate" @_input="changed()" title="Описание:" class="w-100"/>

              <v-btn icon color="error" @click="askDelete(interval)"><v-icon>mdi-delete-outline</v-icon></v-btn>
            </div>
          </div>
          <div class="row row-complete-item" :key="-2">
            <v-btn class="add-button" fab dark small color="primary" @click="addItem()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </transition-group>
      </div>

      <div class="error-message" :class="{ shake: showShake }">{{ errorMessage }}</div>
      <div class="row mt-4 justify-end">
        <v-btn class="mr-3 _w-100" @click="dialogEditShow=false"> Закрыть </v-btn>
        <v-btn class="mr-3 _w-100" color="primary" @click="okClicked" :loading="saving"> Сохранить </v-btn>
      </div>
    </v-card>
    <AskDialog v-model="showAskDeleteDialog" @ok="delItem(curInterval)" />
  </v-dialog>
</template>

<script>
/*eslint-disable*/
import { mapActions, mapState, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import AskDialog from "@/components/ui/AskDialog"
import HtmlContentEditor from '@/components/testEditor/htmlEditor/HtmlContentEditor.vue';

export default {
  name: 'EditIntervalsDialog',
  components: {
    AskDialog, HtmlContentEditor
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: Boolean,
    block: Object,
  },
  data: () => ({
    intervals: [],
    showAskDeleteDialog: false,
    curInterval: null,
    errorIntervals: [],
    errorMessage: '',
    showShake: false,
    saving: false,
    forceUpdate: false,
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
        this.intervals = cloneDeep(this.srcIntervals)
        this.intervals = this.intervals.map(el => ({...el, idTmp: el.id}))
        this.intervals.sort((a, b) => a.value_from - b.value_from)
        this.forceUpdate = !this.forceUpdate
        this.clearError()
      }
    }
  },
  computed: {
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', false);
      }
    },
    ...mapGetters({
      loading: 'testEditor/loading',
      allIntervals: 'testEditor/intervals',
    }),
    srcIntervals() {
      return this.allIntervals.filter(el => el.block === this.block.id)
    }

  },

  methods: {
    ...mapActions({
      saveInterval: 'testEditor/saveInterval',
      addInterval: 'testEditor/addInterval',
      delInterval: 'testEditor/delInterval',
      loadIntervals: 'testEditor/loadIntervals',
    }),
    clearError() {
      this.errorIntervals = []
      this.errorMessage = ''

    },
    setRange(interval, arr) {
      interval.value_from = arr[0]
      interval.value_to = arr[1]
      this.clearError()
    },
    addItem() {
      this.clearError()
      this.intervals.push({
        value_from: 0,
        value_to: 0,
        description: "Описание",
        block: this.block.id,
        idTmp: (new Date()).getTime()
      })
    },
    askDelete(interval) {
      this.curInterval = interval
      this.showAskDeleteDialog = true
    },

    delItem(interval) {
      this.clearError()
      const ind = this.intervals.findIndex(el => el.idTmp === interval.idTmp)
      if (ind>=0) 
        this.intervals.splice(ind, 1);
    },
    checkIntervals () {
      let covered = Array(10).fill(0);
      for (let interval of this.intervals) {
        let from = interval.value_from;
        let to = interval.value_to;

        if (from < 0 || to > 10) return "Интервал выходит за границы диапазона"; 
        if (from > to) return "Интервал наизнанку"; 
        for (let i = from; i < to; i++) {
          if (covered[i]) {
            this.errorIntervals.push(interval.idTmp)
            return "Интервалы не должны пересекаться";
          }
          covered[i]++;
        }
      }

      for (let i = 0; i < 10; i++) {
        if (!covered[i])
          return "Интервалы должны полностью перекрывать диапазон от 0 до 10"
      }
      for (let interval of this.intervals) {
        if (interval.value_from === interval.value_to) {
          this.errorIntervals.push(interval.idTmp)
          return "Не должно быть пустых интервалов";
        }
      }
      return true; 
    }, 

    async okClicked() {
      // this.$emit('ok');
      // this.saveQuestion(this.question)
      this.clearError()
      // this.intervals.sort((a, b) => a.value_from - b.value_from)
      const check = this.checkIntervals ()
      if (check !== true) {
        this.errorMessage = check
        this.showShake = true;
        setTimeout(() => {
            this.showShake = false;
        }, 1000)
        return;
      }

      function copyInterval(dest, src) {
        dest.value_from = src.value_from 
        dest.value_to = src.value_to
        dest.description = src.description
        dest.block = src.block
        return dest
      }

      this.saving = true
      let i = 0
      let countSrc = this.srcIntervals.length
      for (; i<this.intervals.length ; i++) {
        if (i<countSrc) 
          await this.saveInterval(copyInterval(this.srcIntervals[i], this.intervals[i]))
        else
          await this.addInterval(this.intervals[i])
      }

      const toDel = []
      for ( ; i<countSrc ; i++) 
        toDel.push(this.srcIntervals[i])

      for (let interval of toDel) 
        await this.delInterval(interval)

      await this.loadIntervals()
      this.saving = false
      this.dialogEditShow = false

    },
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  height: 16px;
}

.shake {
    animation-name: wobble;
    animation-duration:          0.8s;
    animation-iteration-count:   1;
    animation-timing-function:   linear;
    transform-origin:            50% 100%;
}

@keyframes wobble {
  0% { transform: none; }
  15% { transform: translateX(-4%); }
  30% { transform: translateX(3%) ;  }
  45% { transform: translateX(-2%) ;  }
  60% { transform: translateX(1%) ;  }
  75% { transform: translateX(-0.5%) ;  }
  100% { transform: none; }
}

.interval-ticks {
  font-size: 12px;
}


</style>