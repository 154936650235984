<template>
  <div class="page container edit-test-container pa-1 pa-md-9 ">
    <!-- <h2 class="page-title mb-8 mt-1">Редактор тестов</h2> -->
    <!-- <div class="profile-board ref-board"> -->
    <!-- <QuestionCard class="mb-5"/> -->
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <v-skeleton-loader v-if="loading" class="mx-auto" _max-width="300" type="card"></v-skeleton-loader>
    <TestList v-if="!loading" class="mb-5"/>

  </div>
</template>

<script>
/* eslint-disable */
import TestList from '@/components/testEditor/TestList.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Analytics',
  components: {
    TestList
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  metaInfo: {
    title: 'Конструктор отчетов',
  },
  methods: {
    ...mapActions({
      loadProcessors: 'testEditor/loadProcessors',
      loadInstruments: 'testEditor/loadInstruments',
      loadIntervals: 'testEditor/loadIntervals',
      loadInstrumentSets: 'testEditor/loadInstrumentSets',
      loadBlocks: 'testEditor/loadBlocks',
      // loadClients: 'users/clients/loadClients',
    }),
  },
  async mounted() {
    await this.loadProcessors()
    await this.loadInstruments()
    await this.loadInstrumentSets()
    await this.loadIntervals()
    this.loading = false
  },
  beforeDestroy() {}
};
</script>

<style scoped lang="scss">

.edit-test-container {
    max-width: 1185px;
}

.analytic-page {
  background-color: transparent;
}
.ref-board {
  padding: 50px 40px;
}

.info-panel {
  border-radius: 10px;
  border: 1px solid #F2F4F5;
  background: #FFF;
  color: #25325F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  &__title {
    font-size: 14px;
    font-weight: 400;
  }
  &__value {
    font-size: 22px;
    font-weight: 600;
  }
}


.profession-panel {
  border-radius: 10px;
  border: 1px solid #F2F4F5;
  background: #FFF;
  color: #25325F;
  padding: 30px;
}

.profession-panel__title {
  font-size: 22px;
  font-weight: 600;
}

.profession-panel__subtitle {
  font-size: 16px;
  font-weight: 400;
}


</style>
