import { render, staticRenderFns } from "./HtmlContentEditor.vue?vue&type=template&id=5b81c87b"
import script from "./HtmlContentEditor.vue?vue&type=script&lang=js"
export * from "./HtmlContentEditor.vue?vue&type=script&lang=js"
import style0 from "./HtmlContentEditor.vue?vue&type=style&index=0&id=5b81c87b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VTextarea})
